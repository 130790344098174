import { useStoreState } from "@ariakit/react";
import Badge from "@resource/atlas/badge/Badge";
import { atlasBell } from "@resource/atlas/icons";
import { ListItem } from "@resource/atlas/list-item/ListItem";
import { Popover, usePopoverStore } from "@resource/atlas/popover-v2";
import { useUserCounts } from "client/app/extension/__components/UserCountsProvider";
import React from "react";

import { NotificationList } from "./NotificationList";

export const AppNavNotificationPopover = React.memo(() => {
  const store = usePopoverStore({
    placement: "right-start",
  });
  const state = useStoreState(store);
  const { unreadNotificationCount: unreadCount } = useUserCounts();

  return (
    <Popover.Root store={store}>
      <Popover.Trigger className="w-full">
        <ListItem
          isInteractive
          className="w-full"
          isSelected={state.open}
          icon={atlasBell}
          isSubtleIcon
        >
          <div className="flex items-center justify-between gap-2 w-full">
            <span>Notifications</span>
            {unreadCount > 0 && (
              <Badge variant="primary" styles="bold" size="small">
                {unreadCount}
              </Badge>
            )}
          </div>
        </ListItem>
      </Popover.Trigger>
      <Popover.Content portal fitViewport>
        <NotificationList
          setOpen={store.setOpen}
          className="min-h-[40rem] w-[28.75rem]"
        />
      </Popover.Content>
    </Popover.Root>
  );
});
