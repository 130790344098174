import { MenuButton, useMenuStore, useStoreState } from "@ariakit/react";
import { atlasHelp } from "@resource/atlas/icons";
import { ListItem } from "@resource/atlas/list-item/ListItem";
import { Menu } from "@resource/atlas/menu-v2";

import { useHelpMenuItems } from "./hooks/useHelpMenuItems";

function AppNavHelpMenu() {
  const store = useMenuStore({
    placement: "right-start",
  });
  const state = useStoreState(store);
  const helpMenuItems = useHelpMenuItems();

  return (
    <Menu.Root store={store}>
      <MenuButton>
        <ListItem
          isInteractive
          isSelected={state.open}
          icon={atlasHelp}
          isSubtleIcon
        >
          Support
        </ListItem>
      </MenuButton>
      <Menu.Menu portal>{helpMenuItems}</Menu.Menu>
    </Menu.Root>
  );
}

export default AppNavHelpMenu;
