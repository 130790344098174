import Badge from "@resource/atlas/badge/Badge";
import { Icon } from "@resource/atlas/icon/Icon";
import type { AtlasIconData } from "@resource/atlas/icon/types";
import {
  atlasBookClosed,
  atlasCalendarEvents,
  atlasChart2,
  atlasChevronRight,
  atlasGear,
  atlasJobs,
  atlasLink,
  atlasLockClosed,
  atlasRingPerson,
  atlasTemplate,
} from "@resource/atlas/icons";
import { ListItem } from "@resource/atlas/list-item/ListItem";
import { useLogEvent } from "analytics";
import { Events } from "analytics/types";
import { useAuthContext } from "auth/context";
import {
  displayNameForSchedulingRequestAssigneeFilter,
  useAssigneeFilterParam,
} from "client/app/(scheduling requests)/__utils";
import { GuideLogo } from "client/components/generic/misc/GuideLogo";
import { useReadPersistedFilter } from "client/components/generic/table/utils";
import { AppNavNotificationPopover } from "client/components/notifications/AppNavNotificationPopover";
import { useSchedulingRequestCounts } from "client/hooks/useSchedulingRequestCounts";
import clsx from "clsx";
import { RoleEnum } from "enums/role-enum";
import {
  OrganizationFeaturesEnum,
  SchedulingRequestAssigneeFilter,
} from "generated/graphql-codegen/graphql";
import { camelCase } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { Permission, StaffRoleEnum } from "shared/auth/permissions";
import { formatEntity } from "shared/constants/entities";
import { useResponsiveValue } from "utils/responsive";

import AppNavAccountMenu from "./AppNavAccountMenu";
import AppNavHelpMenu from "./AppNavHelpMenu";
import { useLayoutContext } from "./Layout";
import OrganizationSwitcher from "./OrganizationSwitcher";

interface ParentListItemProps {
  icon: AtlasIconData;
  title: string;
  isGroupActive: boolean;
  isOpen: boolean;
  onToggle: () => void;
  children: ReactNode;
  isHovered: boolean;
}

function ParentListItem({
  icon,
  title,
  isGroupActive,
  isOpen,
  onToggle,
  children,
  isHovered,
}: ParentListItemProps) {
  const isLargeScreen = useResponsiveValue({
    xs: false,
    lg: true,
  });

  return (
    <div className="">
      <ListItem
        icon={icon}
        isSubtleIcon
        isInteractive
        onClick={onToggle}
        className={clsx(
          isOpen &&
            "group-hover:!bg-light-gray-50 group-focus-within:!bg-light-gray-50 lg:!bg-light-gray-50",
          isOpen &&
            "group-hover:!text-subtle group-focus-within:!text-subtle lg:!text-subtle"
        )}
        isSelected={
          isGroupActive && (!isOpen || (!isHovered && !isLargeScreen))
        }
        trailingContent={
          <Icon
            content={atlasChevronRight}
            className="w-5 h-5 transition-transform duration-250"
            style={{ transform: isOpen ? "rotate(90deg)" : "rotate(0deg)" }}
          />
        }
      >
        <span>{title}</span>
      </ListItem>
      <div className="text-body-md pl-7">
        {isOpen && (
          <div
            className={clsx(
              "flex flex-col",
              "h-0 group-hover:h-full  group-focus-within:h-full lg:h-full",
              "opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 lg:opacity-100"
            )}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
}

interface BaseNestedLinkItemProps {
  text: string;
  href: string;
  isNestedActive: boolean;
  show?: boolean;
}

interface OpenGroups {
  schedulingRequests: boolean;
  scheduledInterviews: boolean;
  reports: boolean;
}

interface NestedLinkItemWithBadgeProps extends BaseNestedLinkItemProps {
  badge?: {
    count: number | undefined;
    variant: "primary" | "default";
    styles: "bold" | "subtle";
  };
}

function NestedLinkItem({
  text,
  href,
  isNestedActive,
  show = true,
  badge,
}: NestedLinkItemWithBadgeProps) {
  if (!show) return null;

  return (
    <Link
      href={href}
      data-atlas-wrapper="ListItem"
      className={clsx("nav-target nav-link focus:outline-none scroll-m-[2rem]")}
    >
      <ListItem
        isInteractive
        isSelected={isNestedActive}
        trailingContent={
          badge?.count ? (
            <Badge size="small" variant={badge.variant} styles={badge.styles}>
              {badge.count}
            </Badge>
          ) : null
        }
      >
        {text}
      </ListItem>
    </Link>
  );
}

function useOpenGroups() {
  const router = useRouter();

  // Initialize open states based on current route
  const [openGroups, setOpenGroups] = useState<OpenGroups>({
    schedulingRequests: router.pathname.startsWith("/scheduling-requests"),
    scheduledInterviews: router.pathname.startsWith("/upcoming-interviews"),
    reports: router.pathname.startsWith("/reports"),
  });

  const toggleGroup = (group: keyof OpenGroups) => {
    setOpenGroups((prev) => ({
      ...prev,
      [group]: !prev[group],
    }));
  };

  return {
    openGroups,
    toggleGroup,
  };
}

function SchedulingRequestNavItem({
  isHovered,
  openGroups,
  toggleGroup,
}: {
  isHovered: boolean;
  openGroups: OpenGroups;
  toggleGroup: (group: keyof OpenGroups) => void;
}) {
  const { checkRolePermissions } = useAuthContext();
  const counts = useSchedulingRequestCounts({
    skip: !checkRolePermissions(Permission.SCHEDULING.WRITE),
  });
  const router = useRouter();
  const isActive = router.pathname.startsWith("/scheduling-requests");
  const assigneeFilter = useAssigneeFilterParam();

  return (
    <ParentListItem
      icon={atlasCalendarEvents}
      title={`Scheduling ${formatEntity("request", { plural: true })}`}
      isGroupActive={isActive}
      isOpen={openGroups.schedulingRequests}
      isHovered={isHovered}
      onToggle={() => toggleGroup("schedulingRequests")}
    >
      {Object.values(SchedulingRequestAssigneeFilter).map((type) => {
        const count =
          counts?.[camelCase(type.toLowerCase()) as keyof typeof counts];
        const isSelected = isActive && assigneeFilter === type;

        return (
          <NestedLinkItem
            key={type}
            text={displayNameForSchedulingRequestAssigneeFilter(type)}
            href={`/scheduling-requests?assignee=${type.toLowerCase()}`}
            isNestedActive={isSelected}
            badge={{
              count,
              variant: isSelected ? "primary" : "default",
              styles: isSelected ? "bold" : "subtle",
            }}
          />
        );
      })}
    </ParentListItem>
  );
}

function ScheduledInterviewsNavItem({
  isHovered,
  openGroups,
  toggleGroup,
}: {
  isHovered: boolean;
  openGroups: OpenGroups;
  toggleGroup: (group: keyof OpenGroups) => void;
}) {
  const router = useRouter();
  const { user } = useAuthContext();
  const isActive = router.pathname.startsWith("/upcoming-interviews");

  // Get the persisted filter values for scheduled by and recruiter
  const scheduledByFilter = useReadPersistedFilter<string>("scheduledById");
  const recruiterFilter = useReadPersistedFilter<string>("recruiterId");
  const currentUserMembershipId = user?.currentUserMembership?.id;

  // Check if the current user is the scheduler
  const isScheduledByMe =
    !!scheduledByFilter && scheduledByFilter === currentUserMembershipId;
  // Check if the current user is the recruiter
  const isMyCandidates =
    !!recruiterFilter && recruiterFilter === currentUserMembershipId;

  // Determine the active filter based on the user's role
  const isAllFilter = isScheduledByMe && isMyCandidates;
  const isMyCandidatesFilter = !isAllFilter && isMyCandidates;
  const isScheduledByMeFilter =
    !isAllFilter && !isMyCandidatesFilter && isScheduledByMe;

  let activeFilter = "all";
  if (isAllFilter) {
    activeFilter = "all";
  } else if (isMyCandidatesFilter) {
    activeFilter = "myCandidates";
  } else if (isScheduledByMeFilter) {
    activeFilter = "scheduledByMe";
  }

  return (
    <ParentListItem
      icon={atlasCalendarEvents}
      title="Upcoming interviews"
      isGroupActive={isActive}
      isOpen={openGroups.scheduledInterviews}
      isHovered={isHovered}
      onToggle={() => toggleGroup("scheduledInterviews")}
    >
      <NestedLinkItem
        text="Scheduled by me"
        href={`/upcoming-interviews?filters=%7B"scheduledById"%3A"${currentUserMembershipId}"%7D`}
        isNestedActive={isActive && activeFilter === "scheduledByMe"}
      />
      <NestedLinkItem
        text="My candidates"
        href={`/upcoming-interviews?filters=%7B"recruiterId"%3A"${currentUserMembershipId}"%7D`}
        isNestedActive={isActive && activeFilter === "myCandidates"}
      />
      <NestedLinkItem
        text="All"
        href="/upcoming-interviews"
        isNestedActive={isActive && activeFilter === "all"}
      />
    </ParentListItem>
  );
}

function ReportsNavItem({
  isHovered,
  openGroups,
  toggleGroup,
}: {
  isHovered: boolean;
  openGroups: OpenGroups;
  toggleGroup: (group: keyof OpenGroups) => void;
}) {
  const router = useRouter();
  const isActive = router.pathname.startsWith("/reports");
  const { hasFeatureEnabled } = useAuthContext();
  const surveysEnabled = hasFeatureEnabled(OrganizationFeaturesEnum.SURVEYS);
  const schedulingEnabled = hasFeatureEnabled(
    OrganizationFeaturesEnum.SCHEDULING
  );

  return (
    <ParentListItem
      icon={atlasChart2}
      title="Reports"
      isGroupActive={isActive}
      isOpen={openGroups.reports}
      isHovered={isHovered}
      onToggle={() => toggleGroup("reports")}
    >
      <NestedLinkItem
        text="Scheduling"
        href="/reports/recruiting-team"
        isNestedActive={router.pathname === "/reports/recruiting-team"}
        show={schedulingEnabled}
      />
      <NestedLinkItem
        text="Interviewers"
        href="/reports/interviewers"
        isNestedActive={router.pathname === "/reports/interviewers"}
        show={schedulingEnabled}
      />
      <NestedLinkItem
        text="Training"
        href="/reports/training/events"
        isNestedActive={router.pathname.startsWith("/reports/training")}
        show={schedulingEnabled}
      />
      <NestedLinkItem
        text="Surveys"
        href="/reports/surveys"
        isNestedActive={router.pathname === "/reports/surveys"}
        show={surveysEnabled}
      />
    </ParentListItem>
  );
}

// nav link data
// -------------

type BaseNavItem = {
  hide?: () => boolean;
};

type BasicNavItem = BaseNavItem & {
  icon: AtlasIconData;
  url: string;
  analyticsMessage: Events;
  title: string;
  trailingContent?: ReactNode;
  forceRefresh?: boolean;
  className?: string;
};

type CustomNavItem = BaseNavItem & {
  component: () => JSX.Element;
};

type NavItem = BasicNavItem | CustomNavItem;

function useNavItems({
  isHovered,
  openGroups,
  toggleGroup,
}: {
  isHovered: boolean;
  openGroups: OpenGroups;
  toggleGroup: (group: keyof OpenGroups) => void;
}) {
  const {
    checkRolePermissions,
    highestRole,
    hasFeatureEnabled,
    checkStaffRole,
  } = useAuthContext();

  return useMemo(
    (): NavItem[] => [
      {
        title: "My profile",
        icon: atlasRingPerson,
        url: "/settings/profile",
        analyticsMessage: "NavBar Button My Profile Clicked",
        hide: () => highestRole !== RoleEnum.Interviewer,
      },
      {
        component: () => (
          <SchedulingRequestNavItem
            key="scheduling-requests"
            isHovered={isHovered}
            openGroups={openGroups}
            toggleGroup={toggleGroup}
          />
        ),
        hide: () =>
          !hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) ||
          !checkRolePermissions(Permission.SCHEDULING.READ),
      },
      {
        title: "Candidates",
        icon: atlasRingPerson,
        url: "/candidates",
        analyticsMessage: "NavBar Button Candidates Clicked",
        hide: () => !checkRolePermissions(Permission.GUIDE.READ),
      },
      {
        component: () => (
          <ScheduledInterviewsNavItem
            key="scheduled-interviews"
            isHovered={isHovered}
            openGroups={openGroups}
            toggleGroup={toggleGroup}
          />
        ),
        hide: () =>
          !hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) ||
          !checkRolePermissions(Permission.SCHEDULING.READ),
      },
      {
        title: formatEntity("booking link", { plural: true, capitalize: true }),
        icon: atlasLink,
        url: "/booking-links",
        analyticsMessage: "NavBar Button Booking Links Clicked",
        hide: () =>
          !hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) ||
          !checkRolePermissions(Permission.SCHEDULING.READ),
      },
      {
        title: formatEntity("training path", {
          capitalize: true,
          plural: true,
        }),
        icon: atlasBookClosed,
        url: "/interviewers/pools",
        analyticsMessage: "NavBar Button Interviewer Pools Clicked",
        hide: () =>
          !hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) ||
          !checkRolePermissions(Permission.TAGS.WRITE),
      },
      {
        title: "Jobs",
        icon: atlasJobs,
        url: "/jobs",
        hide: () => !checkRolePermissions(Permission.JOB.WRITE),
        analyticsMessage: "NavBar Button Jobs Clicked",
      },
      {
        title: "Templates",
        icon: atlasTemplate,
        url: "/templates",
        hide: () =>
          !checkRolePermissions(Permission.GUIDE_TEMPLATE.WRITE) &&
          !checkRolePermissions(Permission.POST_TEMPLATE.WRITE),
        analyticsMessage: "NavBar Button Templates Clicked",
      },
      {
        component: () => (
          <ReportsNavItem
            key="reports"
            isHovered={isHovered}
            openGroups={openGroups}
            toggleGroup={toggleGroup}
          />
        ),
        hide: () =>
          (!hasFeatureEnabled(OrganizationFeaturesEnum.SCHEDULING) ||
            !checkRolePermissions(Permission.SCHEDULING.READ)) &&
          (!hasFeatureEnabled(OrganizationFeaturesEnum.SURVEYS) ||
            !checkRolePermissions(Permission.SURVEYS.READ)),
      },
      {
        title: "Internal dashboards",
        icon: atlasLockClosed,
        url: "/internal",
        hide: () => !checkStaffRole(StaffRoleEnum.SUPERUSER),
        analyticsMessage: "NavBar Button Internal Dashboards Clicked",
      },
      {
        title: "Internal reporting",
        icon: atlasLockClosed,
        url: "/reports/automation",
        hide: () => !checkStaffRole(StaffRoleEnum.STAFF),
        analyticsMessage: "NavBar Button Internal Reporting Clicked",
      },
    ],
    [
      checkRolePermissions,
      checkStaffRole,
      hasFeatureEnabled,
      isHovered,
      openGroups,
      toggleGroup,
      highestRole,
    ]
  );
}

// nav logo
// --------

function NavLogo() {
  const { checkStaffRole } = useAuthContext();
  const logEvent = useLogEvent({ component: "NavLogo" });
  const hideV2Label = checkStaffRole(StaffRoleEnum.STAFF);

  return (
    <div className="px-4 py-[1.125rem] w-[16rem]">
      <Link
        href="/"
        aria-label="Home"
        className="focus:focus-ring-0 flex flex-row items-center space-x-2"
        onClick={() => logEvent("NavBar Button Resource Logo Clicked")}
        // prevent keyboard focus to prevent issues with keyboard navigation
        // the first nav link is the same, so it doesn't break accessibility
        tabIndex={-1}
        // remove focus to prevent the nav from remaining expanded after navigation
        onPointerUp={(e) => e.currentTarget.blur()}
      >
        <GuideLogo
          showV2Label={!hideV2Label}
          logoClassName="pl-[.1875rem]"
          textClassName="transition-opacity opacity-0 group-hover:opacity-100 group-focus-within:opacity-100 lg:opacity-100"
        />
      </Link>
    </div>
  );
}

// nav footer
// -----------

const NavFooter = React.memo(() => {
  const { user } = useAuthContext();
  const router = useRouter();
  return (
    <div className="px-2 py-3 border-t border-gray-border mt-auto">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col">
          <OrganizationSwitcher showOrgName />
          <AppNavHelpMenu />
          <NavLink
            hide={!user}
            title="Settings"
            icon={atlasGear}
            url="/settings"
            analyticsMessage="NavBar Button Settings Clicked"
            currentPath={router.pathname}
            key="/settings"
            className="tours__org-settings-nav"
          />
          <div data-atlas-wrapper="ListItem">
            <AppNavAccountMenu hideDashboard />
          </div>
        </div>
      </div>
    </div>
  );
});

// nav links
// ---------

type NavLinkProps = Omit<BasicNavItem, "hide"> & {
  hide: boolean | (() => boolean);
  isDisabled?: boolean;
  currentPath: string;
  isFirst?: boolean;
  className?: string;
};

function NavLink({
  hide,
  isDisabled,
  currentPath,
  isFirst,
  icon,
  url,
  title,
  analyticsMessage,
  trailingContent,
  forceRefresh = false,
  className,
}: NavLinkProps) {
  const { restorePageFocus } = useLayoutContext();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const router = useRouter();
  const logEvent = useLogEvent({ component: "AppNav" });

  if ((typeof hide === "function" ? hide() : hide) && !isDisabled) return null;

  const isActive =
    url === "/" ? currentPath === url : currentPath.startsWith(url);

  function moveFocus(direction: "up" | "down") {
    if (!linkRef.current) return;
    let element: HTMLElement | null;
    if (direction === "up")
      element = linkRef.current.previousElementSibling as HTMLElement | null;
    else element = linkRef.current.nextElementSibling as HTMLElement | null;

    if (element?.classList.contains("nav-link")) {
      element?.scrollIntoView?.({ block: "nearest" });
      element?.focus?.({ preventScroll: true });
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    logEvent(analyticsMessage);
    if (forceRefresh) {
      e.preventDefault();
      const newKey = Math.random().toString(36).substring(2, 15);
      router.replace(`${url}?key=${newKey}`, undefined, { shallow: true });
    }
  };

  const listItem = (
    <ListItem
      icon={icon}
      isDisabled={isDisabled}
      isSubtleIcon
      isInteractive
      isSelected={isActive}
      onClick={() => logEvent(analyticsMessage)}
      trailingContent={trailingContent}
    >
      {title}
    </ListItem>
  );

  return isDisabled ? (
    listItem
  ) : (
    <Link
      href={url}
      ref={linkRef}
      data-atlas-wrapper="ListItem"
      className={clsx(
        "nav-target nav-link focus:outline-none scroll-m-[2rem]",
        {
          first: isFirst,
          active: isActive,
        },
        className
      )}
      // remove focus to prevent the nav from remaining expanded after navigation
      onPointerUp={(e) => e.currentTarget.blur()}
      onClick={handleClick}
      onKeyDown={(e) => {
        // close on escape
        if (e.key === "Escape") {
          restorePageFocus();
          e.stopPropagation();
        }

        // arrow navigation
        if (
          ["ArrowUp", "ArrowDown", "j", "k"].includes(e.key) &&
          (e.target as Element | null)?.classList?.contains("nav-link")
        ) {
          moveFocus(["ArrowUp", "k"].includes(e.key) ? "up" : "down");
          e.preventDefault();
        }
      }}
    >
      {listItem}
    </Link>
  );
}

const NavLinks = React.memo(({ isHovered }: { isHovered: boolean }) => {
  const { openGroups, toggleGroup } = useOpenGroups();
  const navItems = useNavItems({
    isHovered,
    openGroups,
    toggleGroup,
  });
  const router = useRouter();
  const { user } = useAuthContext();

  return (
    <div className="pb-4 pt-2 px-2 overflow-y-auto flex-grow">
      <AppNavNotificationPopover />
      {navItems.map((item, i) => {
        if (!("icon" in item)) {
          if (item.hide?.()) return null;
          return item.component();
        }

        const { hide, ...data } = item;
        return (
          <NavLink
            {...data}
            hide={!user || hide?.() || false}
            currentPath={router.pathname}
            key={data.url}
            isFirst={i === 0}
          />
        );
      })}
    </div>
  );
});

// app nav
// -------

function AppNav({ className }: { className?: string }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={clsx(
        "flex-shrink-0 w-[3.75rem] lg:w-[16rem] relative group bg-light-gray-50",
        className
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={clsx(
          "absolute flex flex-col h-full overflow-hidden border-r transition-all",
          "border-[#eeedef] group-hover:border-transparent group-focus-within:border-transparent lg:group-hover:border-[#eeedef] lg:group-focus-within:border-[#eeedef]",
          "group-hover:shadow-20 group-focus-within:shadow-20 lg:group-hover:shadow-none lg:group-focus-within:shadow-none",
          "w-[3.75rem] group-hover:w-[16rem] group-focus-within:w-[16rem] lg:w-[16rem] bg-light-gray-50"
        )}
      >
        <NavLogo />
        <NavLinks isHovered={isHovered} />
        <NavFooter />
      </div>
    </div>
  );
}

export default React.memo(AppNav);
